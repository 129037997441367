import React from "react";
import Helmet from "react-helmet";

import { Props } from "./types";

const SEO = (props: Props): JSX.Element => {
  const { metaDescription, title, type, bodyClass } = props;
  let { image } = props;

  if (image && image) {
    if (image.startsWith("//")) {
      image = "https:" + image;
    }
  }

  const helmetProps = {
    bodyAttributes: {
      class: bodyClass || "",
    },
    htmlAttributes: {
      lang: "sv",
    },
  };

  return (
    <Helmet defer={false} {...helmetProps}>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {title && <meta property="twitter:title" content={title} />}
      {title && <meta property="og:image:alt" content={title} />}
      {title && <meta property="twitter:image:alt" content={title} />}

      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaDescription && (
        <meta name="og:description" content={metaDescription} />
      )}

      {image && <meta property="og:image" content={image} />}
      {image && <meta property="twitter:image:src" content={image} />}

      {type && <meta property="og:type" content={type} />}
      {type && <meta property="twitter:type" content={type} />}

      {type && <meta property="og:url" content={url} />}
      {type && <meta property="twitter:url" content={url} />}
      <html lang="sv" />
    </Helmet>
  );
};

export default SEO;
