import React from 'react'
import Layout from 'src/components/Layout/Layout'
import Wrapper from 'src/components/Wrapper/Wrapper'
import 'src/styles/global.scss'

export const wrapRootElement = Wrapper

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

const supportsWebp = async () => {
  if (!window.createImageBitmap) return false

  const webpData =
    'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA='
  const blob = await fetch(webpData).then((r) => r.blob())

  return createImageBitmap(blob).then(
    () => true,
    () => false
  )
}

export const onClientEntry = async () => {
  // NOTE: Don't polyfill Promise here (Gatsby already includes a Promise polyfill)

  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
    // console.log(`👍 IntersectionObserver is polyfilled`)
  }

  if (document) {
    const htmlEL = document.getElementsByTagName('html')[0]

    // Check if client supports webP
    if (await supportsWebp()) {
      htmlEL.classList.add('webp-checked')
      htmlEL.classList.add('support-webp')
    } else {
      htmlEL.classList.add('webp-checked')
    }
  }
}
