import React from 'react'
import { Flex, Box } from 'reflexbox'

export const Container = (props: any) => <Box {...props} />

Container.defaultProps = {
  paddingX: [9, null, null, 9],
  width: 1,
  maxWidth: '792px', // Padding x included
  margin: 'auto',
}

export const Row = (props: any) => <Flex {...props} />

Row.defaultProps = {
  marginX: ['-16px', null, null, '-32px'],
  flexDirection: ['column', null, null, 'row'],
  flexWrap: 'wrap',
}

export const Column = (props: any) => <Box {...props} />

Column.defaultProps = {
  position: 'relative',
  width: '100%',
  minHeight: '1px',
  paddingX: [4, null, null, '32px'],
}
