import React from "react";

import { Flex } from "reflexbox";

import { Container } from "src/components/UI/Grid/Grid";

import SbEditable from "storyblok-react";
import * as styles from "./Footer.module.scss";
import { Props } from "./types";
import Markdown from "src/components/Markdown/Markdown";

const Footer = ({ blok }: Props): JSX.Element => {
  const { text } = blok || {};
  return (
    <SbEditable content={blok}>
      <Container as="footer" className={styles.wrapper} paddingY={[6]}>
      </Container>
    </SbEditable>
  );
};

export default Footer;
